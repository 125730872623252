<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/bill/unPayBill"
    downloadResource="/bill/export"
    :downloadParams="{exportFlag: 'UNPAY'}"
    :settingButtonList="['refresh', 'download']"
    :filterFields="filterFields"
    :columns="columns"
    :defaultFilterBy="{unPayPeriods: 'CURRENT'}"
    :defaultSortBy="[{prop: 'billMonth', order: 'desc'}]"
    :operateButtonList="operateButtonList"
    :toolbarButtonList="toolbarButtonList"
    :operationWidth="100"
    @rowDblClick="handleRowDblClick")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'

export default {
  name: 'BillArreargeQuery',
  data () {
    const actionRights = this.$getPageActions([
      { name: 'detail', url: '/billManage/billArreargeQuery/detail' }
    ])
    return {
      showTable: false,
      // 表格筛选表单配置 用户名称/水厂/抄表员/账单产生日期/缴费日期
      filterFields: [{
        name: 'unPayPeriods',
        label: '欠费类型',
        form: {
          tag: 'select',
          relation: 'billUnPayPeriods',
          chains: ({ chains, values, value }) => {
            chains.setState('unPayNum', (state) => {
              state.form.visabled = value === 'NUMS'
            })
          }
        }
      }, {
        name: 'unPayNum',
        label: '欠费期数',
        form: {
          tag: 'number',
          visabled: false,
          rules: [{ required: true, message: '必填' }]
        }
      }, {
        name: 'usewaterCode',
        label: '用水户号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerCode',
        label: '用户编号',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'customerName',
        label: '用户姓名',
        form: {
          tag: 'input',
          like: true
        }
      }, {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          multiple: true,
          collapseTags: true,
          clearable: true,
          filterable: true,
          relation: 'waterWorks'
        }
      }, {
        name: 'billMonth',
        label: '账期',
        form: {
          tag: 'date',
          dateType: 'monthrange',
          valueFormatType: 'yyyy-MM',
          timeType: 'YYYY-MM'
        }
      }],
      // 表格列配置
      columns: [
        { name: 'billMonth', label: '账期', minWidth: 80 },
        { name: 'billNo', label: '账单编号', minWidth: 130 },
        { name: 'usewaterCode', label: '用水户号', minWidth: 100 },
        {
          name: 'customerName',
          label: '用户',
          width: 160,
          render: (value, field, data) => {
            const { customerName, customerCode } = data
            return `${customerName}(${customerCode})`
          }
        },
        { name: 'usewaterAddress', label: '用水地址', minWidth: 180 },
        { name: 'orgName', label: '水厂', minWidth: 100 },
        { name: 'quantity', label: '水量' },
        { name: 'totalAmount', label: '总金额', minWidth: 100 },
        { name: 'waterAmount', label: '水费总额', minWidth: 100 },
        { name: 'sewageAmount', label: '污水费总额', minWidth: 100 },
        { name: 'waterResourceAmount', label: '水资源费总额', minWidth: 100 },
        { name: 'penaltyAmount', label: '滞纳金总额', minWidth: 100 },
        { name: 'penaltyDays', label: '滞纳天数' },
        { name: 'billCreateTime', label: '账单产生日期', minWidth: 120, render: { type: 'date' } }
      ],
      toolbarButtonList: [],
      operateButtonList: [{
        label: '详情',
        name: 'check',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.detail,
        func: ({ data }) => {
          this.$router.push({
            name: 'billDetail',
            params: {
              billId: data.id
            }
          })
        }
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    handleRowDblClick (row) {
      const detailButton = this.operateButtonList[0]
      detailButton.showabled && detailButton.func({ data: row })
    },
    renderRelation () {
      this.getRelations(['billPayStatus', 'payType', 'billUnPayPeriods', 'waterWorks'])
        .then(res => {
          renderRelationColumns(res, [this.columns, this.filterFields])
          this.$nextTick(() => { this.showTable = true })
        })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.form-box
  position: relative
  padding-right: 8px
</style>
